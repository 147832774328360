<template>
  <v-main>
    <v-row no-gutters class="h-100">
      <v-col cols="4" class="left-side dark-blue">
        <img src="@/assets/svg/logo.svg" alt="mpawer-logo" class="logo" />
        <img src="@/assets/images/four-paws.png" alt="paws" class="paws" />
      </v-col>
      <v-col cols="8" class="auth-content">
        <v-card
          elevation="0"
          outlined
          rounded="lg"
          max-width="500px"
          width="90%"
          class="pa-3"
        >
          <v-card-text>
            <router-view />
            <div class="caption x-sm d-flex justify-content-evenly">
              <a
                href="https://www.mpawer.be/terms-and-conditions"
                target="_blank"
                class="grey--text text-decoration-none"
                >Terms and Conditions</a
              >
              <a
                href="https://www.mpawer.be/privacy-policy"
                target="_blank"
                class="grey--text text-decoration-none"
                >Privacy Policy</a
              >
              <a
                href="https://www.mpawer.be"
                target="_blank"
                class="grey--text text-decoration-none"
                >Contact</a
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "auth-layout"
};
</script>

<style lang="scss" scoped>
.left-side {
  position: relative;
  img {
    position: absolute;
    max-width: 100%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .logo {
    top: 30%;
  }

  .paws {
    bottom: 0;
  }
}
.auth-content {
  display: grid;
  place-items: center;
  height: 100%;
}
</style>
